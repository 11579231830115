import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import { TextField, SimpleFileUpload, InputBase } from "formik-material-ui";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomPhoneInput from "../components/reusableComponents/CustomPhoneInput";

import PhoneInput from "react-phone-number-input";
import {
  Box,
  Grid,
  Radio,
  Button,
  Backdrop,
  Checkbox,
  FormLabel,
  RadioGroup,
  makeStyles,
  Typography,
  FormControl,
  useMediaQuery,
  LinearProgress,
  FormControlLabel,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@material-ui/core";

import axios from "axios";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { createUser } from "../actions/user";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import Select from "react-select";
import countryList from "react-select-country-list";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: 12,
    padding: "0 30% 0 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      width: "100%",
    },
  },
  fieldLabel: {
    color: theme.palette.defaultBlack.main,
    paddingTop: 2,
    paddingBottom: 2,
  },
  inputField: {
    padding: 20,
    // margin: 20,
  },
  inputField1: {
    padding: 20,
    width: 50,
    // margin: 20,
  },
  errorLabel: {
    color: "red",
    paddingTop: 2,
    paddingBottom: 2,
  },
  facePhoto: {
    padding: 15,
    width: "full",
    height: 100,
    border: "1px solid",
  },
  inputFieldCountrySelector: {
    paddingBottom: 15,
    paddingTop: 15,
    marginTop: 20,
    border: 0,
    borderBottom: "1px solid black",
    width: "100%",
    color: "#8c8c8c",
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,

    // [theme.breakpoints.down('sm')]: {
    //   paddingTop: 15,
    // 	width: '100%',
    //   borderBottom: '1px solid black',
    // },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    marginTop: 8,
    marginBottom: 8,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const validationSchema = yup.object({
  // email: yup
  //   .string("Enter your email")
  //   .email("Enter a valid email")
  //   .required("Email is required"),

  // city: yup.string('Enter City').required('required'),
  // country: yup.string('Enter Country').required('required'),
  firstName: yup.string().required("名字是必须的"),
  nickName: yup.string().required("昵称是必须的"),
  lastName: yup.string().required("姓氏是必须的"),
});

const name_available = async (name) =>
  await axios
    .post("https://admin.cloudflog.com/api/user/checkUsername/", {
      nickName: name,
    })
    .then((res) => res.data.msg === "Valid");

const email_available = async (name) =>
  await axios
    .post("https://admin.cloudflog.com/api/user/checkUsername/", {
      email: name,
    })
    .then((res) => res.data.msg === "Valid");

const mobile_available = async (name) =>
  await axios
    .post("https://admin.cloudflog.com/api/user/checkUsername/", {
      mobile_number: name,
    })
    .then((res) => res.data.msg === "Valid");

const ZhRegistrationForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const phoneNumber = localStorage.getItem("phoneNumber");
  const emailId = localStorage.getItem("email");
  const countryCodes = localStorage.getItem("countryCode");
  const [role, setRole] = useState("");
  const [value, setValue] = useState("");
  const [isBusinessChecked, setIsBusinessChecked] = useState("");
  const [isTradeChecked, setIsTradeChecked] = useState("");
  const [error, setError] = useState();
  const [facePhoto, setFacePhoto] = useState(null);
  const [profile_pic, setProfile_pic] = useState(null);
  const [businessCard, setBusinessCard] = useState(null);
  const [internationalWatchTradeShowImg, setInternationalWatchTradeShowImg] =
    useState(null);
  const [countryCode, setCountryCode] = React.useState("");
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);

  const [isShopChecked, setIsShopChecked] = useState(false);
  const [isWebsiteChecked, setIsWebsiteChecked] = useState(false);
  const [isCompanyChecked, setIsCompanyChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const [isMember, setMember] = useState(false);
  // const [isBuyer, setBuyer] = useState(false);
  // const [isSeller, setSeller] = useState(false);

  const onTradeChecked = (event) => setIsTradeChecked(event.target.value);
  const onBusinessChecked = (event) => setIsBusinessChecked(event.target.value);

  const handleSubmit = async (data, actions) => {
    console.log("GEt DATA::::", emailId);
    console.log("GEt DATA::::", phoneNumber);
    let result = data.countryCode.concat(data.mobileNo);
    console.log(
      "Get Number Of Concat::",
      result,
      data.countryCode,
      data.mobileNo
    );
    setIsLoading(true);
    console.log("SUBMIT", data, actions);
    if (data.password !== data.confirmPassword) {
      setIsLoading(false);
      setError("Password and Confirm Password are not same");
      actions.setSubmitting(false);
    } else if (data.securityQues1 === "" && data.securityQues2 === {}) {
      setIsLoading(false);
      setError("Answer at least 1 question");
      actions.setSubmitting(false);
    } else if (!(await name_available(data.nickName))) {
      setIsLoading(false);
      setError("The Nickname is taken. Try another");
      actions.setSubmitting(false);
    } else if (data.email == "") {
      setIsLoading(false);
      setError("Please enter email.");
      actions.setSubmitting(false);
    } else if (!(await email_available(data.email))) {
      setIsLoading(false);
      setError("The Email is taken. Try another");
      actions.setSubmitting(false);
    } else if (!(await mobile_available(result))) {
      setIsLoading(false);
      setError("The Mobile Number is taken. Try another");
      actions.setSubmitting(false);
    } else if (data.country == "") {
      setIsLoading(false);
      setError("Selected country not available");
      actions.setSubmitting(false);
    } else {
      console.log("SUBMIT DATA", data);
      setError(null);

      // localStorage.setItem("email", data.email);
      var regiFormData = new FormData();
      regiFormData.append("email", data.email);
      regiFormData.append("password", data.password);
      if (phoneNumber == undefined || phoneNumber == "") {
        regiFormData.append("mobile_number", result);
      } else {
        regiFormData.append("mobile_number", data.mobileNo);
      }

      // regiFormData.append('city', data.city);
      regiFormData.append("country", data.country);
      regiFormData.append("nameOfTwoDealers", data.securityQues1);
      regiFormData.append("credibleReferences", "");
      // regiFormData.append('securityQues2', {
      //   shop: data.securityQues2Shop,
      //   website: data.securityQues2Website,
      //   company: data.securityQues2Company,
      // });
      regiFormData.append("securityQues2Shop", data.securityQues2Shop);
      regiFormData.append("securityQues2Website", data.securityQues2Website);
      regiFormData.append("securityQues2Company", data.securityQues2Company);
      regiFormData.append("is_member_of_watchtrade", isTradeChecked);
      regiFormData.append("is_on_other_platform", isTradeChecked); // ADDED
      regiFormData.append("trade_group_name", data.tradeGroups);
      regiFormData.append("other_platform_name", data.otherPlatform);
      regiFormData.append("first_name", data.firstName);
      regiFormData.append("last_name", data.lastName);
      regiFormData.append("nickName", data.nickName);
      facePhoto && regiFormData.append("facePhoto", facePhoto, facePhoto?.name);
      profile_pic &&
        regiFormData.append("profile_pic", profile_pic, profile_pic?.name); // for verification purpose
      !!businessCard
        ? regiFormData.append("businessCard", businessCard, businessCard?.name)
        : regiFormData.append("businessCard", businessCard);
      !!businessCard
        ? regiFormData.append(
            "internationalWatchTradeShowImg",
            internationalWatchTradeShowImg,
            internationalWatchTradeShowImg?.name
          )
        : regiFormData.append(
            "internationalWatchTradeShowImg",
            internationalWatchTradeShowImg
          );
      // console.log('Submit', regiFormData.getE);
      for (var pair of regiFormData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      dispatch(createUser(regiFormData, history, setError, setIsLoading, "zh"));
      actions.setSubmitting(false);
    }
  };

  const UploadButton = ({ id, value, onChange, acceptFile, selected }) => (
    <label htmlFor={id}>
      <Box
        border={"1px  dashed gray"}
        borderRadius={5}
        width="75%"
        height="auto"
        paddingY={5}
        align="center"
      >
        {!selected ? (
          <CloudUploadIcon style={{ fontSize: 50, color: "gray" }} />
        ) : null}
        <Typography
          style={{
            paddingTop: "2px",
            color: "#ffffff",
            width: "50%",
            height: "2rem",
            backgroundColor: "#232323",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          {selected ? selected : "选择档案"}
        </Typography>

        <input
          id={id}
          // accept="image/*"
          accept={acceptFile}
          name="facePhoto"
          value={value}
          onChange={onChange}
          style={{ display: "none" }}
          type="file"
        />
      </Box>
    </label>
  );

  const handleFacePhoto = ({ target: { files } }) => setFacePhoto(files[0]);
  const handleProfilePic = ({ target: { files } }) => setProfile_pic(files[0]);

  const handleBusinessCard = ({ target: { files } }) =>
    setBusinessCard(files[0]);

  const handleInternationalWatchShow = ({ target: { files } }) =>
    setInternationalWatchTradeShowImg(files[0]);

  const options = useMemo(() => countryList().getData(), []);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: emailId == undefined ? "" : emailId,
        city: "",
        country: "",
        password: "",
        confirmPassword: "",
        mobileNo: phoneNumber == undefined ? "" : phoneNumber,
        securityQues1: "",
        // securityQues2: { shop: '', website: '', company: '' },
        securityQues2Website: "",
        securityQues2Shop: "",
        securityQues2Company: "",
        tradeGroups: "",
        otherPlatform: "",
        nickName: "",
        countryCode: "+852",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, setFieldValue, values }) => (
        <Form className={classes.formContainer}>
          <Field
            fullWidth
            type="text"
            name="firstName"
            label="名字 *"
            variant="standard"
            component={TextField}
            value={values.firstName}
            inputProps={{ className: classes.inputField }}
          />
          <Field
            fullWidth
            type="text"
            name="lastName"
            label="姓氏 *"
            variant="standard"
            component={TextField}
            value={values.lastName}
            inputProps={{ className: classes.inputField }}
          />
          <Field
            fullWidth
            type="text"
            name="nickName"
            label="昵称 *"
            variant="standard"
            component={TextField}
            value={values.nickName}
            inputProps={{ className: classes.inputField }}
          />

          <Box display="flex" alignItems="center">
            <Field
              fullWidth
              name="countryCode"
              type="countryCode"
              label="国家 *"
              value={values.countryCode}
              component={TextField}
              style={{ marginBottom: 20, width: 120, marginRight: 20 }}
              inputProps={{ className: classes.inputField1 }}
            />
            <Field
              fullWidth
              name="mobileNo"
              type="mobileNo"
              label="电话号码 *"
              variant="standard"
              value={values.mobileNo}
              component={TextField}
              style={{ marginBottom: 20 }}
              inputProps={{ className: classes.inputField }}
            />
          </Box>

          <Select
            options={options}
            aria-label="country"
            // value={values.country}
            placeholder="选择国家"
            classes={classes.inputFieldCountrySelector}
            onChange={(obj) => setFieldValue("country", obj.value)}
          />

          <Typography style={{ paddingTop: 25, paddingBottom: 25 }}>
            如果你想在不填写所需凭证的情况下进入组，你可以直接跳转到提交。
            我们将会与你联络，要求你提供留在此网络上所需的必要凭证。
          </Typography>
          <Box display="flex" alignItems="center">
            <Checkbox
              color="primary"
              checked={isShopChecked}
              onChange={() => setIsShopChecked(!isShopChecked)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <Typography>
              I agree to the{" "}
              <Link
                href="https://www.cloudflog.com/terms-of-use"
                target="_blank" // Opens link in a new tab
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </Link>{" "}
              and{" "}
              <Link
                href="https://www.cloudflog.com/privacy-policy"
                target="_blank" // Opens link in a new tab
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
            </Typography>
          </Box>
          {error ? (
            <Typography style={{ color: "red" }}>{error}</Typography>
          ) : null}
          <Button
            variant="contained"
            onClick={() => {
              setIsSubmitPressed(true);
              submitForm();
            }}
            fullWidth
            className={classes.submitButton}
            disabled={isLoading ? true : false}
          >
            提交
          </Button>
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Form>
      )}
    </Formik>
  );
};

export default ZhRegistrationForm;
